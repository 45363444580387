.contacts-title {
    padding: 30px 0px 0px 30px;
}

.contact-form {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.4px;
    background-color: rgb(46, 46, 46);
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;
}

.form-container {
    display: block;
    margin: 0px auto;
    padding: 0px 20px;
    box-sizing: border-box;
}

.contact-form textarea {
    /* width: 100%; */
    padding: 0px 0;
    background: none;
    min-height: 35px;
    border: none;
    border-bottom: 1px solid #666;
    color: #ddd;
    font-size: 18px;
    line-height: 18px;
    outline: none;
    transition: border-color .2s;
    resize: none;
    margin-top: 20px;
}

.message-field {
    height: 100px;
}

.contact-form textarea::placeholder {
    color: #666;
    font-weight: 500;
}

.submit-button {
    margin-top: 30px;
    color: #ddd;
    background: none;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    text-align: left;
    padding: 0px;
    align-self: flex-start;
    border-bottom: 3px solid transparent;
    position: relative;
}

.submit-button:hover {
    color: #fff;
    transition: color 0.5s;
}

.submit-button:hover::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 0;
    width: 0;
    z-index: 1;
    border-bottom: 3px solid #fff;
    animation: grow 0.5s ease-out;
    animation-fill-mode: forwards;
}
  
  @keyframes grow {
    100% {
        width: 100%;
    }
}

.contact-form textarea:focus {
    border-bottom-color: #ddd;
}

.contact-information {
    margin-top: 30px;
    font-size: 1rem;
    color: #fff;
    /* vertical-align: middle; */
}

.contact-icon {
    font-size: 1.2rem;
    margin-right: 20px;
}

.contact-link {
    color: inherit;
    text-decoration: none;
}

.contact-link:hover {
    color: inherit;
}

.information-container {
    margin-top: 5px;
    display: flex;
    align-items: center;
}