.time {
    background-color: rgb(46, 46, 46);
    /* background-color: rgb(242, 242, 242); */
    font-family: "Montserrat", sans-serif;
    color: rgb(225, 225, 225);
    padding: 40px 0px;
    font-size: 0.9rem;
}

.people {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    margin-top: 20px;
}

.person {
    padding: 0px 20px 20px 20px;
}

.parceiros-main-title {
    color: rgb(255, 255, 255);
}

.parceiros-subtitle-text {
    color: rgb(225, 225, 225);
}

.parceiros-subtitle-strip {
    background-color: rgb(225, 225, 225);
    opacity: 1;
}

.parceiros-container {
    margin: 0px;
}

.parceiros-title {
    float: right;
    display: block;
}

.parceiros-title-text {
    float: right;
    margin-right: 60px;
}

.person-image {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-right: 20px;
}

.person-description {
    text-align: justify;
}

.top-person {
    display: flex;
    margin-bottom: 20px;
}

.person-name {
    display: inline-block;
    font-weight: 600;
    color: rgb(241, 241, 241);
}

.person-title {
    display: inline-block;
}

.person-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.1rem;
}