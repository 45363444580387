.projects-gallery {
    margin-top: 10px;
}

.wrap {
    overflow: hidden;
    display: block;
 }

 .box {
    float: left;
    position: relative;
    width: 25%;
    padding-bottom: 25%;
 }

 .boxInner {
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    overflow: hidden;
    font-weight: 800;
 }

 .flash-class {
    -webkit-animation: flash 1s;
    animation: flash 0.6s;
    animation-delay: 0.5s;
}

 @-webkit-keyframes flash {
    0%, 50%, 100% { opacity: 1; }
    25%, 75% { opacity: 0; }
}
@keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

 .boxInner img {
    width: 100%;
    border: rgb(46,46,46) solid 2px;
 }

 .boxInner .titleBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    color: #fff;
    display: inline-block;
    z-index: 1;
    text-align: center;
    background-color: rgb(46,46,46, 0.6);
    border-radius: 10px;
    padding: 10px;
 }

.boxInner:hover {
    /* font-weight: 900; */
    background-color: rgb(46,46,46);
}

.boxInner:hover img {
    opacity: 0;
    transition: 0.8s;
}

.boxInner:hover .titleBox {
    transition: 0.5s;
    opacity: 0;
}

 body.no-touch .boxInner:hover .titleBox, body.touch .boxInner.touchFocus .titleBox {
    margin-bottom: 0;
 }

 @media only screen and (max-width : 650px) {
    /* Smartphone view: 1 tile */
    .box {
       width: 100%;
       padding-bottom: 100%;
    }
 }

 @media only screen and (max-width : 850px) and (min-width : 651px) {

    .box {
       width: 50%;
       padding-bottom: 50%;
    }
 }

 @media only screen and (max-width : 1050px) and (min-width : 851px) {
    /* Small desktop / ipad view: 3 tiles */
    .box {
       width: 33.3%;
       padding-bottom: 33.3%;
    }
 }
 @media only screen and (max-width : 1290px) and (min-width : 1051px) {
    /* Medium desktop: 4 tiles */
    .box {
       width: 25%;
       padding-bottom: 25%;
    }
 }

.project-info {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    z-index: 1000;
    position: absolute;
    top: 5px;
    left: 10px;
    opacity: 0;
    color: rgb(0,0,0,0)
}

.boxInner:hover .project-info {
    display: inline;
    color: #fff;
    opacity: 1;
    transition: 0.5s;
}

.bold-span {
    font-weight: 700;
}

.project-info-item {
    font-size: 1.05rem;
    line-height: 160%;
}

.red-border-image {
    border: solid 10px var(--darkRed) !important;
}