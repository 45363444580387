.footer {
    background-color: var(--darkRed);
    color: #fff;
    height: 130px;
    margin-top: 40px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    /* position: absolute;
    bottom: 0; */
}

.footer-text {
    margin-bottom: 0px;
    margin-right: 7.5vw;
}