.background-header {
    width: 100vw;
    background-color: rgba(7, 7, 7, 0);
    overflow-y: hidden;
    position: relative;
}

.header-slider {
    -webkit-filter: grayscale(50%); /* Safari 6.0 - 9.0 */
    filter: grayscale(50%);
    width: 100vw;
    position: absolute;
    z-index: -1;
}

.header-content {
    position: absolute;
    top: 16.5%;
    left: 7%;
    max-width: 90vw;
    font-weight: bold;
    text-align: start;
    color: rgb(0, 0, 0);
}

.header-text {
    font-family: 'Source Serif Pro', serif;
    font-size: 3.4rem;
    margin-bottom: 30px;
    transition: 2s;
    animation: 1.5s ease-out 0s 1 appearAnimation;
}

@keyframes appearAnimation {
    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}  

.header-caption {
    font-family: 'Montserrat', sans-serif;
    display: inline;
    border-bottom: solid 4.5px var(--darkRed);
    font-weight: 400;
    transform: translateX(-10px);
    transition: all 250ms ease-in-out;
    font-size: 1.5rem;
    animation: 1.5s ease-out 0s 1 slideInFromLeft;
}

.sobrenos-link {
    text-decoration: none;
    color: #000;
}

.sobrenos-link:hover {
    color: #000;
}

.header-caption:hover {
    letter-spacing: 2px;
    border-bottom-width: 3px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 990px) {
    .header-content {
        left: 7%;
    }

    .header-text {
        font-size: 2rem;
    }
    
    .header-content {
        font-size: 1rem;
    }

    .header-caption {
        display: none;
    }
}

.red-stripe {
    height: 15px;
    width: 100vw;
    background-color: var(--darkRed);
    transition: .5s ease-out;
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
}

@media (max-width: 991px) {
    .red-stripe {
        display: none;
    }
}

.video-wrapper {
    position: relative;
    overflow: hidden;
}

#background-video {
    /* background-color: tomato; */
    /* position: fixed; */
    z-index: -1;
    width: 100vw;
    height: 100vh;
    /* top:0;
    left: 0; */
    object-fit: cover;
}