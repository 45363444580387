.about-section {
    min-height: 50vh;
    background-color: rgb(242, 242, 242);
    padding: 0px;
    margin: 0px;
    padding-top: 25px;
}

.img-about {
    height: 80vh;
    margin-right: auto;
    margin-top: 20px;
    object-fit: fill;
}

.img-about-container {
    padding: 0px;
}

.about-content-container {
    /* background-color: tomato; */
    padding: 20px;
    padding-left: 30px;
}

.row {
    width: 100vw;
}

.about-container {
    margin: 0px;
    padding: 0px;
    margin-right: 0px;
}

.about-title {
    color: #000;
    font-family: 'Source Serif Pro', serif;
    font-size: 3rem;
    margin-bottom: 30px;
}

.red-text {
    color: var(--darkRed);
}

.about-subtitle-text {
    color: rgb(72, 72, 72);
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    font-weight: 300;
    margin-bottom: 10px;
    display: inline;
    line-height: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.subtitle-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.subtitle-strip {
    height: 5px;
    width: 70px;
    display: inline;
    background-color: #a9a9a9;
    opacity: 0.5;
    margin-right: 20px;
    margin-left: 5px;
}

.about-paragraph {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    text-align: justify;
}



@media (max-width: 991px) {
    .about-content-container {
        margin: 0px;
        padding-top: 0px;
    }
}

#img-about-container {
    display: flex;
    justify-content: space-evenly;
}

.table-imgs {
    margin-top: 20px;
    padding: 20px;
}

.table-imgs tr {
    margin-top: 30px;
    text-align: center;
}

.img-ball {
    width: 225px;
    height: 225px;
    border-radius: 100%;
    margin: 10px;
    cursor: pointer;
    padding: 20px;
    background-color: rgb(255, 255, 255);
}

.img-ball:hover {
    transition: box-shadow 0.2s ease-in-out;
}

@keyframes appearFromLeft {
    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}  

.img-ball-1 {
    position: relative;
    top: 30px;
    animation: 1.5s ease-out 0s 1 appearFromLeft;
}

.img-ball-2 {
    left: 15px;
    position: relative;
    animation: 1.5s ease-out 0s 1 appearFromLeft;
}

.img-ball-3 {
    position: relative;
    left: 40px;
    bottom: 30px;
    padding: 20px;
    width: 275px;
    height: 275px;
    padding: 0px;
    animation: 1.5s ease-out 0s 1 appearFromLeft;
}

/* .img-ball-3:hover {
    -webkit-filter: grayscale(50%);
    filter: invert(30%);
    transition: filter 0.5s ease-in-out;
} */

.reveal{
    position: relative;
    opacity: 0;
    transition: opacity 1s ease;
}
  
.reveal.active{
    opacity: 1;
    transform: translateY(0);
    transform: translateX(0);
    opacity: 1;
}

.reveal-4 {
    transform: translateY(0px);
    background-color: transparent;
    transition: 0.5s all ease-in-out;
}

@media (max-width: 991px) {
    .about-content-container {
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 30px;
    }

    .row {
        width: 100vw;
    }
}

@media (max-width: 501px) {
    .img-ball {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 430px) {
    .img-ball {
        width: 150px;
        height: 150px;
    }
}