.investors-title {
    padding: 30px 0px 0px 30px;
}

.container-downloads {
    margin: 0px;
}

.coluna-download {
    padding: 0px 20px;
}

.investors-content {
    background-color: rgb(242, 242, 242);
}

.coluna-download:nth-of-type(2) {
    border-left: 1px solid rgb(128, 128, 128);
}

@media screen and (max-width: 990px) {
    .coluna-download:nth-of-type(2) {
        border-top: 1px solid rgb(128, 128, 128);
        border-left: none;
        margin-top: 10px;
        padding-top: 15px;
    }
}

.coluna-title {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    text-align: center;
}

.files-download {
    list-style: none;
    padding: 0px;
}

.file-item:nth-child(1) {
    border-top: 2px dotted var(--darkRed);
}

.file-item {
    border-bottom: 2px dotted var(--darkRed);
    padding: 10px;
    display: flex;
}

.download-icon {
    float: right;
    font-size: 1.5rem;
}

.file-link {
    width: 100%;
    color: #000;
    text-decoration: none;
    font-weight: 700;
}

.file-link:hover {
    color: #000;
    text-decoration: none;
}