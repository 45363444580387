.navbar {
    background-color: #fff;
}

.logo-img {
    margin: 20px;
    height: 80px;
}

.navbar-brand:hover {
    opacity: 0.5;
}

.navbar-nav {
    margin-left: auto;
    margin-top: 42px;
    margin-right: 100px;
}

.nav-link {
    margin-left: 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    padding-bottom: 0px;
    height: 40px;
    display: inline-block;
    color: rgb(48, 48, 48);
}

@media (min-width: 990px) {
    .nav-link:after {
        display: block;
        content: '';
        border-bottom: solid 3px var(--darkRed);
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      .nav-link:hover {
        color: #000;
      }
    
    .nav-link:hover:after { transform: scaleX(1); }
    .nav-link:after{  transform-origin:  0% 50%; }
}

@media (max-width: 990px) {
    .nav-link {
        background: linear-gradient(to left, #fff 50%, var(--darkRed) 50%) right;
        background-size: 200%;
        transition: .5s ease-out;
    }

    .navbar-nav {
        margin-top: 0px;
    }

    .nav-link:hover {
        background-position: left;
        color: #fff;
    }

    .container-fluid, .navbar-nav, .nav-link  {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }

    .nav-link {
        padding-left: 20px;
    }

    .logo-img {
        height: 65px;
    }
}

/* TOGGLE STYLE */
.navbar-toggler {
    width: 30px;
    height: 30px;
    position: relative;
    margin-right: 30px;
    transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
    position: relative;
}

.navbar-toggler span {
    margin: 0;
    padding: 0;
}

.toggler-icon {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.middle-bar {
    margin-top: 0px;
}


/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
    position: absolute;
    top: 0px;
    transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
    position: absolute;
    top: 10px;
    filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
    position: absolute;
    top: 20px;
    transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
    top: inherit;
    transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
    opacity: 0;
    top: inherit;
    filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
    top: inherit;
    transform: rotate(-135deg);
}

/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
    background: linear-gradient( 263deg, rgba(0,0,0) 0%, rgba(0,0,0) 100% );
}

.language-container {
    position: fixed;
    right: 10px;
    /* top: 54.5px; */
    top: 6.5px;
    display: flex;
    flex-direction: column;
    z-index: 2000;
}

.flag {
    width: 30px;
    /* height: 20px; */
    margin-top: 5px;
    cursor: pointer;
}

@media (max-width: 990px) {
    .language-container {
        flex-direction: row;
        top: 5px;
        right: 5px;
    }
    
    .flag {
        margin-top: 0px;
        margin-left: 5px;
    }
}