.projects-container {
    padding-top: 30px;
    margin: 0px;
}

.projects-list {
    display: none;
}

.projects-content {
    margin: 0px;
    padding-bottom: 30px;
}

.projects-title {
    margin-left: 30px;
}

.brazil-img {
    height: 400px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

@media (max-width: 991px) {
    .brazil-img-container {
        margin-top: 40px;
    }
}

.projects-heading-text {
    margin-bottom: 0px;
}

.brazil-img-container {
    text-align: center;
}

.projects-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.projects-text {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    padding: 10px 30px 0px 30px;
}

.projects-list {
    padding: 0;
    width: 100vw;
    margin: 0;

}

.project-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.project-item:before {
    margin: auto;
    text-align: center;
    width: 100vw;
    height: 100%;
    content: "PONGAÍ";
    position: absolute;
    font-style: italic;
    color: rgb(187, 182, 182);
    top: 0;
    left: 0;
    font-size: 9rem;
    z-index: -1;
}

.reverse-flex {
    flex-direction: row-reverse !important;
}

.project-item-img {
    width: 200px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.26) 0px 3px 5px;
}

.project-item-name {
    display: inline-block;
}

.project-button {
    background-color: rgb(233, 233, 233);
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.red-border-project {
    border: 3px solid var(--darkRed) !important;
    display: none;
}

/* .project-button-text {
    display: none;
    opacity: 0;
} */

/* .project-button:hover .project-button-text {
    display: inline;
    color: #fff;
    opacity: 1;
    transition: 0.3s ease-in-out;
    transition-delay: 30s;
    font-family: 'Montserrat', sans-serif;
} */

/* .project-button:hover {
    border-radius: 5px;
    height: 50px;
    width: 100px;
    transform: translate(-50px, -25px);
    transition: 0.3s ease-in-out;
    z-index: 1000;
} */